import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guards';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'prefix' },   
  { path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
  },
  { path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule), canActivate: [AuthGuard] 
  },
  { path: 'app-setting',
    loadChildren: () => import('./app-setting/app-setting.module').then(m => m.AppSettingModule), canActivate: [AuthGuard] 
  },
  { path: 'teacher',
    loadChildren: () => import('./teacher/teacher.module').then(m => m.TeacherModule), canActivate: [AuthGuard] 
  },
  { path: 'government-degree',
    loadChildren: () => import('./government-degree/government-degree.module').then(m => m.GovernmentDegreeModule), canActivate: [AuthGuard] 
  },
  { path: 'private-degree',
    loadChildren: () => import('./private-degree/private-degree.module').then(m => m.PrivateDegreeModule), canActivate: [AuthGuard] 
  },
  { path: 'government-diploma',
    loadChildren: () => import('./government-diploma/government-diploma.module').then(m => m.GovernmentDiplomaModule), canActivate: [AuthGuard] 
  },
  { path: 'private-diploma',
    loadChildren: () => import('./private-diploma/private-diploma.module').then(m => m.PrivateDiplomaModule), canActivate: [AuthGuard] 
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
