import { Pipe, PipeTransform } from '@angular/core';

/**
 * Generated class for the SearchPipe pipe.
 *
 * See https://angular.io/api/core/Pipe for more info on Angular Pipes.
 */
@Pipe({
  name: 'governmentDiplomaSearch',
})
export class GovernmentDiplomaSearchPipe implements PipeTransform {
  /**
   * Takes a value and makes it lowercase.
   */
  transform(items: any[], terms: string): any[] {
    if(!items) return [];
    if(!terms) return items;
    terms = terms.toLowerCase();
    return items.filter( item => {
      return item.GovernmentInstituteName_ENG.toLowerCase().includes(terms) ||                           
             item.GovernmentDiplomaPeriodName_ENG.toLowerCase().includes(terms) ||
             item.GovernmentDiplomaName_SIN.toLowerCase().includes(terms) ||
             item.GovernmentDiplomaName_TAM.toLowerCase().includes(terms) ||
             item.GovernmentDiplomaName_ENG.toLowerCase().includes(terms) ||
             item.GovernmentDiplomaDetail_SIN.toLowerCase().includes(terms) ||
             item.GovernmentDiplomaDetail_TAM.toLowerCase().includes(terms) ||
             item.GovernmentDiplomaDetail_ENG.toLowerCase().includes(terms)
    });
  }
}
