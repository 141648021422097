import { Pipe, PipeTransform } from '@angular/core';

/**
 * Generated class for the SearchPipe pipe.
 *
 * See https://angular.io/api/core/Pipe for more info on Angular Pipes.
 */
@Pipe({
  name: 'subDegreeSearch',
})
export class SubDegreeSearchPipe implements PipeTransform {
  /**
   * Takes a value and makes it lowercase.
   */
  transform(items: any[], terms: string): any[] {
    if(!items) return [];
    if(!terms) return items;
    terms = terms.toLowerCase();
    return items.filter( item => {
        return item.SubDegreeName_SIN.toLowerCase().includes(terms) ||              
            item.SubDegreeName_TAM.toLowerCase().includes(terms) ||
            item.SubDegreeName_ENG.toLowerCase().includes(terms)                          
    });
  }
}
