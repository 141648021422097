import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LanguageSearchPipe } from './search/language.search';
import { StreamSearchPipe } from './search/stream.search';
import { SubjectSearchPipe } from './search/subject.search';
import { UniversitySearchPipe } from './search/university.search';
import { FacultySearchPipe } from './search/faculty.search';
import { DegreePeriodSearchPipe } from './search/degree-period.search';
import { DegreeSearchPipe } from './search/degree.search';
import { SubjectDegreeCategorySearchPipe } from './search/subject-degree-category.search';
import { SubjectDegreeGroupSearchPipe } from './search/subject-degree-group.search';
import { SubDegreeSearchPipe } from './search/sub-degree.search';
import { ExamYearSearchPipe } from './search/exam-year.search';
import { DistrictSearchPipe } from './search/district.search';
import { ResultDegreeEligibilitySearchPipe } from './search/result-degree-eligibility.search';
import { ResultDegreeSearchPipe } from './search/result-degree.search';
import { AppModuleSearchPipe } from './search/app-module.search';
import { AppPageSearchPipe } from './search/app-page.search';
import { PrivateUniversitySearchPipe } from './search/private-university.search';
import { PrivateDegreePeriodSearchPipe } from './search/private-degree-period.search';
import { PrivateDegreeCategorySearchPipe } from './search/private-degree-category.search';
import { PrivateDegreeSearchPipe } from './search/private-degree.search';
import { Tea_GradeSearchPipe } from './search/tea-grade.search';
import { Tea_TownSearchPipe } from './search/tea-town.search';
import { PrivateInstituteSearchPipe } from './search/private-institute.search';
import { PrivateDiplomaCategorySearchPipe } from './search/private-diploma-category.search';
import { PrivateDiplomaSearchPipe } from './search/private-diploma.search';
import { NotificationSearchPipe } from './search/notification.search';
import { GovernmentInstituteSearchPipe } from './search/government-institute.search';
import { GovernmentDiplomaCategorySearchPipe } from './search/government-diploma-category.search';
import { GovernmentDiplomaSearchPipe } from './search/government-diploma.search';

@NgModule({
  declarations: [ 
    LanguageSearchPipe,
    AppModuleSearchPipe,
    AppPageSearchPipe,  
    NotificationSearchPipe,  
    Tea_GradeSearchPipe,    
    Tea_TownSearchPipe,
    StreamSearchPipe,
    SubjectSearchPipe,
    UniversitySearchPipe,
    FacultySearchPipe,
    DegreePeriodSearchPipe,
    DegreeSearchPipe,
    SubjectDegreeCategorySearchPipe,
    SubjectDegreeGroupSearchPipe,
    SubDegreeSearchPipe,
    ExamYearSearchPipe,
    DistrictSearchPipe,
    ResultDegreeEligibilitySearchPipe,
    ResultDegreeSearchPipe,
    PrivateUniversitySearchPipe,    
    PrivateDegreePeriodSearchPipe,
    PrivateDegreeCategorySearchPipe,
    PrivateDegreeSearchPipe,
    GovernmentInstituteSearchPipe,
    GovernmentDiplomaCategorySearchPipe,
    GovernmentDiplomaSearchPipe,
    PrivateInstituteSearchPipe,
    PrivateDiplomaCategorySearchPipe,
    PrivateDiplomaSearchPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [ 
    LanguageSearchPipe,
    AppModuleSearchPipe,
    AppPageSearchPipe,  
    NotificationSearchPipe,  
    Tea_GradeSearchPipe,    
    Tea_TownSearchPipe,
    StreamSearchPipe,
    SubjectSearchPipe,
    UniversitySearchPipe,
    FacultySearchPipe,
    DegreePeriodSearchPipe,
    DegreeSearchPipe,
    SubjectDegreeCategorySearchPipe,
    SubjectDegreeGroupSearchPipe,
    SubDegreeSearchPipe,
    ExamYearSearchPipe,
    DistrictSearchPipe,
    ResultDegreeEligibilitySearchPipe,
    ResultDegreeSearchPipe,
    PrivateUniversitySearchPipe,    
    PrivateDegreePeriodSearchPipe,
    PrivateDegreeCategorySearchPipe,
    PrivateDegreeSearchPipe,
    GovernmentInstituteSearchPipe,
    GovernmentDiplomaCategorySearchPipe,
    GovernmentDiplomaSearchPipe,
    PrivateInstituteSearchPipe,
    PrivateDiplomaCategorySearchPipe,
    PrivateDiplomaSearchPipe
  ]
})
export class PipesModule { }
