import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgHttpLoaderModule } from 'ng-http-loader'; 
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { AppRoutingModule } from './app-routing.module';
import { PipesModule } from './pipes/pipes.module';
import { AppComponent } from './app.component';
import { LocalstorageService } from './localstorage.service';
import { AlertComponent } from './common/alert/alert.component';
import { DeleteAlertComponent } from './common/delete-alert/delete-alert.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import { QuillModule } from 'ngx-quill'

@NgModule({
  declarations: [
    AppComponent,
    AlertComponent,
    DeleteAlertComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    PipesModule,
    HttpClientModule,
    MatDialogModule,
    MatTabsModule,
    QuillModule.forRoot(),
    NgHttpLoaderModule.forRoot(),
    BrowserAnimationsModule,
    FontAwesomeModule
  ],
  providers: [ LocalstorageService ],
  bootstrap: [ AppComponent ],
  entryComponents: [
    AlertComponent,
    DeleteAlertComponent
  ]
})
export class AppModule { }
