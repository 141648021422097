import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor( private cookieService: CookieService,
               private router: Router){}
  canActivate(): boolean {
    if (this.cookieService.get('isLoggedin')) {
      return true;
    }

    this.router.navigate(['/login']);
    return false;
  }

}
